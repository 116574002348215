import React from "react";
import ReactPlayer from "react-player";
import "./Examples.css";

function Examples() {
  return (
    <div className="examples__section">
      <div className="examples__wrapper">
        <h1 className="examples__category">Workouts</h1>
        <div className="examples__container">
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/753955542/rendition/540p/file.mp4?loc=external&signature=40aa592ea17caf03bf4a22aa98eba6a0ea39d4b3bc949f06edec5986e60fd91f"
              controls
              height="auto"
              width="100%"
              playing
              muted
            />
          </div>
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/749163521/rendition/540p/file.mp4?loc=external&signature=78bf78f1a50043f0a5e7730f40be58443ce0e901f4b00c444b46b418746e3a17"
              controls
              height="auto"
              width="100%"
              playing
              muted
            />
          </div>
        </div>
      </div>
      <div className="examples__wrapper">
        <h1 className="examples__category">Parkour</h1>
        <div className="examples__container">
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/753954734/rendition/540p/file.mp4?loc=external&signature=37e91e14182a7639d0591862c09f2c3da352402ac0d78746a6c913c5f0692675"
              controls
              height="auto"
              width="100%"
            />
          </div>
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/749163359/rendition/540p/file.mp4?loc=external&signature=671a18fecc006163817258d5e79961696b6685650f3faa4d51bf23ab47e349e2"
              controls
              height="auto"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className="examples__wrapper">
        <h1 className="examples__category">Games</h1>
        <div className="examples__container">
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/753953839/rendition/540p/file.mp4?loc=external&signature=013c46bb4459a88a93278156a7d18aa7b49a9abf04a1edbc9b5581f4239c758a"
              controls
              height="auto"
              width="100%"
            />
          </div>
          <div className="examples__container-video">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/753954305/rendition/540p/file.mp4?loc=external&signature=f8b1399e89bf438d55362583b8292e47b949a639ac453cbea69da24e763503c6"
              controls
              height="auto"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Examples;
