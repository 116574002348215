import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home";
import CodePage from "./pages/CodePage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ErrorPage, { errorStatus } from "./pages/ErrorPage";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Examples from "./pages/Examples";

function App() {
  return (
    <div className="App">
      <Router>
        {window.location.pathname === "/code" || window.location.pathname.includes("/p/") ? null : <Navbar />}
        <div className="content">
          <ScrollToTop>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/code" exact element={<CodePage />} />
              <Route path="/p/:id" exact element={<CodePage />} />
              <Route path="/examples" exact element={<Examples />} />
              <Route path="/products" exact element={<Products />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/datenschutz" exact element={<Datenschutz />} />
              <Route path="/impressum" exact element={<Impressum />} />
              <Route path="*" element={<ErrorPage status={errorStatus.notFound} />} />
            </Routes>
          </ScrollToTop>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
