export const aboutData = {
  isPrimary: true,
  topLine: "Über uns",
  headline: "QUICR ist ein QR-Code basierter Service zur Bewegungsförderung",
  description: "Wir sind in der Lage, Menschen auf spielerische Art und Weise in Bewegung zu versetzten.",
  buttonLabel: "Trailer ansehen",
  buttonModalVideoUrl:
    "https://player.vimeo.com/progressive_redirect/playback/749424818/rendition/720p/file.mp4?loc=external&signature=48285256f532bd1a67d08f0d67e0cbfe38748a2a8041da1a3003a8dd1275cf6f",
  imgStart: false,
  img: "images/IMG_7064.jpg",
  alt: "Code scanning",
};

export const whereData = {
  isPrimary: true,
  topLine: "Wo?",
  headline: "Für folgende Bereiche geeignet:",
  description: `- Neue und bestehende Sport- und Spielanlagen.
- Öffentlicher Raum (Fußgängerzone, Parks, Erholungsgebiete, etc.)
    
Unsere QR Codes bieten Ihnen eine direkte Kommunikation mit den Nutzer_innen Ihrer Bewegungsräume. Dabei beschränken wir uns nicht nur auf Sportplätze, sondern möchten auch ungewöhnliche oder temporäre Bewegungsräume im öffentlichen Raum noch zugänglicher machen.`,
  buttonLabel: null,
  imgStart: true,
  img: "images/IMG_7056.jpg",
  alt: "QR-Code",
};

export const whatData = {
  isPrimary: true,
  topLine: "Was?",
  headline: "Vielseitige Anwendungsbereiche",
  description: `- Video: Challenges / Tutorials / Spiele
- Gebrauchsanleitung für (Trend-)Sportanlagen
- Individuelle Trainingspläne
- Individuelle Verlinkungen`,
  buttonLabel: "Beispiele ansehen",
  buttonLink: "/examples",
  imgStart: false,
  img: "images/game-lvl1.png",
  alt: "Parkour Workout",
};

export const servicesData = {
  isPrimary: true,
  topLine: "Usere Services",
  headline: "QUICR bietet Ihnen folgende Services:",
  description: `- Individuelle Erstellung der Inhalte (Challenges, Workouts, etc.) 
- Produktion der Inhalte vor Ort 
- Herstellung der QR Code Tiles 
- Verwaltung der QR Codes 
- Hosting der Inhalte 
- Branding durch Kunden möglich`,
  imgStart: true,
  img: "images/IMG_6915.jpg",
  alt: "Video shoot with drones",
};
