import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
// import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        {/* <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/sign-up" reloadDocument>How it works</Link>
            <Link to="/" reloadDocument>Testimonials</Link>
            <Link to="/" reloadDocument>Careers</Link>
            <Link to="/" reloadDocument>Investors</Link>
            <Link to="/" reloadDocument>Terms of Service</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/" reloadDocument>Contact</Link>
            <Link to="/" reloadDocument>Support</Link>
            <Link to="/" reloadDocument>Destinations</Link>
            <Link to="/" reloadDocument>Sponsorships</Link>
          </div>
        </div> */}
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Hilfreiche Informationen</h2>
            <Link to="/datenschutz" reloadDocument>
              Datenschutzerklärung
            </Link>
            <Link to="/impressum" reloadDocument>
              Impressum
            </Link>
            <Link to="/contact" reloadDocument>
              Kontakt
            </Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="Quicr Logo" reloadDocument>
              <img height="60px" src={"../images/logo_main_light.png"} alt="logo" />
            </Link>
          </div>
          <small className="website-rights">
            QUICR ist ein Label von{" "}
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              rel="noreferrer"
              href="https://www.trace-space.com/"
              target="_blank"
            >
              TraceSpace
            </a>
          </small>
          {/* <div className="social-icons">
            <Link className="social-icon-link" to="/" target="_blank" aria-label="Facebook">
              <FaFacebook />
            </Link>
            <Link className="social-icon-link" to="/" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </Link>
            <Link className="social-icon-link" to="/" target="_blank" aria-label="Youtube">
              <FaYoutube />
            </Link>
            <Link className="social-icon-link" to="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </Link>
            <Link className="social-icon-link" to="/" target="_blank" aria-label="LinkedIn">
              <FaLinkedin />
            </Link>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;
