import { useEffect, useState } from "react";
import "./CodePage.css";
import ReactPlayer from "react-player";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";
import { updateViewCount } from "../Backend";
import { Oval } from "react-loader-spinner";

function CodePage() {
  const [codeData, setCodeData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Getting the scanned id
  const { id } = useParams();
  const [seachParams] = useSearchParams();
  const codeID = id ?? seachParams.get("id") ?? "";

  // Loading the code data stored in firestore
  useEffect(() => {
    const getCodeDocument = async () => {
      const codeDocRef = doc(db, "Customers", codeID.substring(0, 4), "Codes", codeID.substring(4, 8));

      getDoc(codeDocRef).then((doc) => {
        const data = doc.data();
        setCodeData(data);

        if (data !== undefined)
          updateViewCount(codeDocRef).then(() => {
            if (data.redirect_url) window.location.replace(data.redirect_url);
          });

        if (!data?.redirect_url) setLoading(false);
      });
    };

    if (codeID.length === 8) getCodeDocument();
    else setLoading(false);
  }, [codeID]);

  return (
    <>
      <div className="code-section">
        <Link to="/" reloadDocument>
          <img height="80px" src={"../images/logo_offen_light.png"} alt="Quicr Logo" />
        </Link>
        {isLoading ? (
          <div className="loading-indicator">
            <Oval
              height={80}
              width={80}
              color="#df6400"
              secondaryColor="#4c4c4c"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : codeID.length !== 8 || codeData === undefined ? (
          <div className="code-title">Es wurde keine gültige QR-Code ID angebeben</div>
        ) : codeData.video_url == null ? (
          <>
            <div className="code-title">Der Code wurde erfolgreich gescannt.</div>
            <div className="code-title">
              Wir arbeiten aktuell noch an dem Content. In kürze steht der Service zur Verfügung.
            </div>
          </>
        ) : (
          <>
            <div className="code-title">{codeData.title}</div>
            <div className="code-video">
              <ReactPlayer
                url={codeData.video_url}
                controls
                playing
                muted
                playsinline
                height={window.innerWidth < 960 ? "auto" : "75vh"}
                width={window.innerWidth < 960 ? "90vw" : "auto"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CodePage;
