import React from "react";

function Datenschutz() {
  return (
    <>
      <div className="datenschutz-impressum">
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <div>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
          passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
          identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
          diesem Text aufgeführten Datenschutzerklärung.
        </div>
        <div></div>
        <h3>Datenerfassung auf unserer Website</h3>
        <div>
          <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
        </div>
        <div>
          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
          dem Impressum dieser Website entnehmen.
        </div>
        <div>
          <strong>Wie erfassen wir Ihre Daten?</strong>
        </div>
        <div>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten
          handeln, die Sie in ein Kontaktformular eingeben.
        </div>
        <div>
          Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
          Daten erfolgt automatisch, sobald Sie unsere Website betreten.
        </div>
        <div>
          <strong>Wofür nutzen wir Ihre Daten?</strong>
        </div>
        <div>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
          können zur Analyse Ihres Nutzerverhaltens verwendet werden.
        </div>
        <div>
          <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
        </div>
        <div>
          Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
          dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
          zuständigen Aufsichtsbehörde zu.
        </div>
        <div>
          Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf
          Einschränkung der Verarbeitung“.
        </div>
        <div></div>
        <h3>Analyse-Tools und Tools von Drittanbietern</h3>
        <div>
          Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
          mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
          anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen
          oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in
          der folgenden Datenschutzerklärung.
        </div>
        <div>
          Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
          Datenschutzerklärung informieren.
        </div>
        <div></div>
        <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
        <h3>Datenschutz</h3>
        <div>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
          personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </div>
        <div>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
          erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </div>
        <div>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
          Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </div>
        <div></div>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <div>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</div>
        <div>
          TraceSpace Scheffler &amp; Gessinger GbR
          <br />
          Dietzgenstraße 25
          <br />
          13156 Berlin
          <br />
          Deutschland
          <br />
          Tel.:&nbsp;<span>+49 30 48 49 42 41</span>
        </div>
        <div>
          E-Mail: info(at)
          <a
            href="https://trace-space.com/"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://trace-space.com&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw1obO37EbDsqluJOvii2t7d"
          >
            trace-space.com
          </a>
        </div>
        <div>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
          die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
          entscheidet.
        </div>
        <div></div>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <div>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
          bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
          Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </div>
        <div></div>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <div>
          <strong>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit
            das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
            personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
            Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser
            Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten
            nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
          </strong>
        </div>
        <div>
          <strong>
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
            jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger
            Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung
            steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der
            Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
          </strong>
        </div>
        <div>
          <strong>&nbsp;</strong>
        </div>
        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
        <div>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
          mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </div>
        <div></div>
        <h3>Recht auf Datenübertragbarkeit</h3>
        <div>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </div>
        <div></div>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
        <div>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
          Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
          “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </div>
        <div>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
          Dritten mitgelesen werden.
        </div>
        <div></div>
        <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>
        <div>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
          über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
          Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen
          Adresse an uns wenden.
        </div>
        <div></div>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>
        <div>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
          können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
          Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </div>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in
            der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der
            Löschung die Einschränkung der Datenverarbeitung verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
            Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und
            unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
            das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <div>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
          Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
          Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
          werden.
        </div>
        <div></div>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>
        <div>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
          ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
          Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
          Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </div>
        <div></div>
        <h2>3. Datenschutzbeauftragter</h2>
        <h3>Gesetzlich vorgeschriebener Datenschutzbeauftragter</h3>
        <div>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</div>
        <div>
          <div>Benjamin Scheffler</div>
          <div>TraceSpace Scheffler &amp; Gessinger GbR</div>
          <div>Dietzgenstraße 25, 13156 Berlin</div>
          <div>Deutschland</div>
          <div>
            Tel.:&nbsp;<span>+49 30 48 49 42 41</span>
          </div>
          <div>
            E-Mail: info(at)
            <a
              href="https://trace-space.com/"
              target="_blank"
              rel="noreferrer"
              data-saferedirecturl="https://www.google.com/url?q=https://trace-space.com&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw1obO37EbDsqluJOvii2t7d"
            >
              trace-space.com
            </a>
          </div>
          <div></div>
        </div>
        <h2>4. Datenerfassung auf unserer Website</h2>
        <h3>Cookies</h3>
        <div>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
          an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
          zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
          speichert.
        </div>
        <div>
          Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres
          Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen.
          Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
        </div>
        <div>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur
          im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
          automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann
          die Funktionalität dieser Website eingeschränkt sein.
        </div>
        <div>
          Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter,
          von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
          Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung
          gesondert behandelt.
        </div>
        <div></div>
        <h3>Server-Log-Dateien</h3>
        <div>
          Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
          Ihr Browser automatisch an uns übermittelt. Dies sind:
        </div>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
        <div>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</div>
        <div>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
          berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
          müssen die Server-Log-Files erfasst werden.
        </div>
        <div></div>
        <h3>Kontaktformular</h3>
        <div>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
          inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
          Anschlussfragen bei uns gespeichert. Des Weiteren werden sie in die Liste unseres Newsletter-Tools Mailchimp
          eingepflegt (siehe Kapitel 6. Newsletter). Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die
          Abmeldung vom Newsletter ist jederzeit möglich.
        </div>
        <div>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage
          Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu
          reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </div>
        <div>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
          Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Zwingende
          gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </div>
        <div></div>
        <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
        <div>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche
          Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
          (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
          Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
        </div>
        <div>
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht.
          Gesetzliche Aufbewahrungsfristen bleiben unberührt.
        </div>
        <div></div>
        <h3>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</h3>
        <div>
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
          notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
        </div>
        <div>
          Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich
          zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
          Werbung, erfolgt nicht.
        </div>
        <div>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
          Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </div>
        <div></div>
        <h2>5. Analyse-Tools und Werbung</h2>
        <h3>Google Analytics</h3>
        <div>
          Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </div>
        <div>
          Google Analytics verwendet so genannte “Cookies”. Das sind Textdateien, die auf Ihrem Computer gespeichert
          werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
          Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA
          übertragen und dort gespeichert.
        </div>
        <div>
          Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
          Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.
        </div>
        <div>
          <strong>IP Anonymisierung</strong>
        </div>
        <div>
          Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
          Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
          Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
          Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </div>
        <div>
          <strong>Browser Plugin</strong>
        </div>
        <div>
          Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
          wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie
          erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:&nbsp;
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            rel="noreferrer"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Dde&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw0zO9nr9KdLF9Xq5h8e6qos"
          >
            https://tools.
            <wbr />
            google.com/dlpage/gaoptout?hl=
            <wbr />
            de
          </a>
          .
        </div>
        <div>
          <strong>Widerspruch gegen Datenerfassung</strong>
        </div>
        <div>
          Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken.
          Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website
          verhindert:&nbsp;<p>Google Analytics deaktivieren</p>.
        </div>
        <div>
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von
          Google:&nbsp;
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245?hl%3Dde&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw1KZxt-WdEy7oPb0wjMmiiF"
          >
            https://support.
            <wbr />
            google.com/analytics/answer/
            <wbr />
            6004245?hl=de
          </a>
          .
        </div>
        <div>
          <strong>Auftragsverarbeitung</strong>
        </div>
        <div>
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der
          deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
        </div>
        <div>
          <strong>Demografische Merkmale bei Google Analytics</strong>
        </div>
        <div>
          Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte
          erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
          stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
          können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die
          Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics
          wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
        </div>
        <div></div>
        <h3>Google reCAPTCHA</h3>
        <div>
          Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die Google Inc.,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
        </div>
        <div>
          Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem Kontaktformular)
          durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
          Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der
          Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.
          IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die
          bei der Analyse erfassten Daten werden an Google weitergeleitet.
        </div>
        <div>
          Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen,
          dass eine Analyse stattfindet.
        </div>
        <div>
          Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
          berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM
          zu schützen.
        </div>
        <div>
          Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden
          Links:&nbsp;
          <a
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/intl/de/policies/privacy/&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw0AhU8lycqv8u_g_XL_yTh-"
          >
            https://www.google.com/
            <wbr />
            intl/de/policies/privacy/
          </a>
          &nbsp;und&nbsp;
          <a
            href="https://www.google.com/recaptcha/intro/android.html"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/recaptcha/intro/android.html&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw0XcS7yH1Zl75VfiQjTZi-h"
          >
            <wbr />
            https://www.google.com/
            <wbr />
            recaptcha/intro/android.html
          </a>
          .
        </div>
        <div></div>
        <h2>6. Newsletter</h2>
        <h3>Newsletterdaten</h3>
        <div>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine
          E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
          E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw.
          nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten
          Informationen und geben diese nicht an Dritte weiter.
        </div>
        <div>
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage
          Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
          E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den
          “Austragen”-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
          Widerruf unberührt.
        </div>
        <div>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
          Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten, die zu
          anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
        </div>
        <div></div>
        <h3>MailChimp</h3>
        <div>
          Diese Website nutzt die Dienste von MailChimp für den Versand von Newslettern. Anbieter ist die Rocket Science
          Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
        </div>
        <div>
          MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann.
          Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. E-Mail-Adresse), werden diese auf den Servern
          von MailChimp in den USA gespeichert.
        </div>
        <div>
          MailChimp verfügt über eine Zertifizierung nach dem “EU-US-Privacy-Shield”. Der “Privacy-Shield” ist ein
          Übereinkommen zwischen der Europäischen Union (EU) und den USA, das die Einhaltung europäischer
          Datenschutzstandards in den USA gewährleisten soll.
        </div>
        <div>
          Mit Hilfe von MailChimp können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine mit MailChimp
          versandte E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog. web-beacon) mit den Servern
          von MailChimp in den USA. So kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet und welche Links
          ggf. angeklickt wurden. Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs,
          IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen können nicht dem jeweiligen
          Newsletter-Empfänger zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von
          Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter besser an
          die Interessen der Empfänger anzupassen.
        </div>
        <div>
          Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in
          jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch
          direkt auf der Website abbestellen.
        </div>
        <div>
          Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese
          Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits
          erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </div>
        <div>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
          Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern
          als auch von den Servern von MailChimp gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden
          bleiben hiervon unberührt.
        </div>
        <div>
          Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter:&nbsp;
          <a
            href="https://mailchimp.com/legal/terms/"
            rel="noreferrer"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?q=https://mailchimp.com/legal/terms/&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw2MRuNbq2STltxS-PVsltPo"
          >
            https://mailchimp.com/
            <wbr />
            legal/terms/
          </a>
          .
        </div>
        <div>
          <strong>Abschluss eines Data-Processing-Agreements</strong>
        </div>
        <div>
          Wir haben ein sog. „Data-Processing-Agreement“ mit MailChimp abgeschlossen, in dem wir MailChimp verpflichten,
          die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben. Dieser Vertrag kann unter
          folgendem Link eingesehen werden:&nbsp;
          <a
            href="https://mailchimp.com/legal/data-processing-addendum/"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://mailchimp.com/legal/data-processing-addendum/&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw2dl5NdvnW9GVN6bU3qBFpf"
          >
            https://mailchimp.com/
            <wbr />
            legal/data-processing-
            <wbr />
            addendum/
          </a>
          .
        </div>
        <div></div>
        <h2>7. Plugins und Tools</h2>
        <h3>Google Web Fonts</h3>
        <div>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
          bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </div>
        <div>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen.
          Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die
          Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer
          Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        </div>
        <div>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</div>
        <div>
          Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;
          <a
            href="https://developers.google.com/fonts/faq"
            rel="noreferrer"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?q=https://developers.google.com/fonts/faq&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw3SAHZ375bvedRkqfnZ5Fy3"
          >
            https://developers.
            <wbr />
            google.com/fonts/faq
          </a>
          &nbsp;und in der Datenschutzerklärung von Google:&nbsp;
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw1eG7LgItohq_tlPGMpSVj-"
          >
            https://www.google.
            <wbr />
            com/policies/privacy/
          </a>
          .
        </div>
        <div></div>
        <h3>Adobe Typekit Web Fonts</h3>
        <div>
          Unsere Website nutzt zur einheitlichen Darstellung bestimmter Schriftarten sogenannte Web Fonts von Adobe
          Typekit. Anbieter ist die Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe).
        </div>
        <div>
          Beim Aufruf unserer Seiten lädt Ihr Browser die benötigten Schriftarten direkt von Adobe, um sie Ihrem
          Endgerät korrekt anzeigen zu können. Dabei stellt Ihr Browser eine Verbindung zu den Servern von Adobe in den
          USA her. Hierdurch erlangt Adobe Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
          Bei der Bereitstellung der Schriftarten werden nach Aussage von Adobe keine Cookies gespeichert.
        </div>
        <div>
          Adobe verfügt über eine Zertifizierung nach dem EU-US-Privacy-Shield. Der Privacy-Shield ist ein Abkommen
          zwischen den Vereinigten Staaten von Amerika und der Europäischen Union, das die Einhaltung europäischer
          Datenschutzstandards gewährleisten soll. Nähere Informationen finden Sie unter:&nbsp;
          <a
            href="https://www.adobe.com/de/privacy/eudatatransfers.html"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://www.adobe.com/de/privacy/eudatatransfers.html&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw03zZl5Aly9rbNO8C5GLq7a"
          >
            https://www.adobe.com/
            <wbr />
            de/privacy/eudatatransfers.
            <wbr />
            html
          </a>
          .
        </div>
        <div>
          Die Nutzung von Adobe Typekit Web Fonts ist erforderlich, um ein einheitliches Schriftbild auf unserer Website
          zu gewährleisten. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar.
        </div>
        <div>
          Nähere Informationen zu Adobe Typekit Web Fonts erhalten Sie unter:&nbsp;
          <a
            href="https://www.adobe.com/de/privacy/policies/typekit.html"
            rel="noreferrer"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?q=https://www.adobe.com/de/privacy/policies/typekit.html&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw3LpxP42bMpIfRQDLlQzK7_"
          >
            https://www.adobe.com/
            <wbr />
            de/privacy/policies/typekit.
            <wbr />
            html
          </a>
          .
        </div>
        <div>
          Die Datenschutzerklärung von Adobe finden Sie unter:&nbsp;
          <a
            href="https://www.adobe.com/de/privacy/policy.html"
            rel="noreferrer"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?q=https://www.adobe.com/de/privacy/policy.html&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw1tK0hknjXAyXFAktHBFedk"
          >
            https://www.adobe.com/
            <wbr />
            de/privacy/policy.html
          </a>
        </div>
        <div></div>
        <h3>Google Maps</h3>
        <div>
          Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre
          Parkway, Mountain View, CA 94043, USA.
        </div>
        <div>
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen
          werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser
          Seite hat keinen Einfluss auf diese Datenübertragung.
        </div>
        <div>
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und
          an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        </div>
        <div>
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:&nbsp;
          <a
            href="https://www.google.de/intl/de/policies/privacy/"
            target="_blank"
            rel="noreferrer"
            data-saferedirecturl="https://www.google.com/url?q=https://www.google.de/intl/de/policies/privacy/&amp;source=gmail&amp;ust=1652786415860000&amp;usg=AOvVaw0SsfuiBf1nljVcJDm2ve0g"
          >
            https://www.google.de/
            <wbr />
            intl/de/policies/privacy/
          </a>
          .
        </div>
      </div>
    </>
  );
}

export default Datenschutz;
