import React from "react";
import "./ContactForm.css";
import { Button } from "./Button";

function ContactForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
    window.open(
      `mailto:info@quicr.eu?subject=Kontaktanfrage von ${event.target.name.value}&body=${event.target.message.value}`,
      "_blank"
    );
  };

  return (
    <div className="contact-section">
      <h1 className="contact-heading">Gemeinsam für mehr Bewegung.</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <p className="contact-form_input-identifier">Name</p>
        <input className="contact-form_input" name="name" type="text" placeholder="Name" />
        <p className="contact-form_input-identifier">Email</p>
        <input className="contact-form_input" name="email" type="email" placeholder="beispiel@mail.com" required />
        <p className="contact-form_input-identifier">Nachricht</p>
        <textarea
          className="contact-form_input message-input"
          name="message"
          placeholder="Nachricht"
          required
          minLength="25"
          cols="5"
        ></textarea>
        <Button buttonStyle="btn--primary" buttonSize="btn--wide">
          Senden {/* an info@quicr.eu */}
        </Button>
      </form>
    </div>
  );
}

export default ContactForm;
