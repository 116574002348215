import React, { useState } from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "./HeroSection.modalvideo.css";
import { updateViewCount } from "../Backend";
import { db } from "../firebase-config";
import { doc } from "firebase/firestore";

function HeroSection({
  isPrimary,
  topLine,
  headline,
  description,
  buttonLabel,
  buttonLink,
  buttonModalVideoUrl,
  img,
  alt,
  imgStart,
}) {
  const [isModalVideoOpen, setModalVideoOpen] = useState(false);

  return (
    <>
      {buttonModalVideoUrl ? (
        <ModalVideo
          channel="custom"
          url={buttonModalVideoUrl}
          isOpen={isModalVideoOpen}
          onClose={() => setModalVideoOpen(false)}
        />
      ) : null}
      <div className={isPrimary ? "home__hero-section primaryBg" : "home__hero-section"}>
        <div className="container">
          <div
            className="row home__hero-row"
            style={{ display: "flex", flexDirection: imgStart ? "row-reverse" : "row" }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className={isPrimary ? "heading primary" : "heading"}>{headline}</h1>
                <p className={isPrimary ? "home__hero-description primary" : "home__hero-description"}>{description}</p>
                {!buttonLabel ? null : (
                  <>
                    {buttonLink ? (
                      <Link to={buttonLink}>
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        buttonSize="btn--wide"
                        buttonColor="blue"
                        onClick={() => {
                          setModalVideoOpen(true);
                          updateViewCount(doc(db, "Customers", "v25z", "Codes", "xqkJ")); // update view count of the info video
                        }}
                      >
                        {buttonLabel}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src={img} alt={alt} className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
