import React from "react";
import HeroSection from "../components/HeroSection";
import { aboutData, whereData, whatData, servicesData } from "./HomeData";

function Home() {
  return (
    <>
      <HeroSection {...aboutData} />
      <HeroSection {...whereData} />
      <HeroSection {...whatData} />
      <HeroSection {...servicesData} />
    </>
  );
}

export default Home;
