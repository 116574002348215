import React from "react";

function Impressum() {
  return (
    <>
      <div className="datenschutz-impressum">
        <h2>Impressum</h2>
        <div>
          <div>
            <div>
              <div>
                <strong>Inhalt und Konzept</strong>
              </div>
              <div>
                <div>
                  <div>TraceSpace – Scheffler &amp; Gessinger GbR</div>
                  <div>
                    Dietzgenstraße 25
                    <br />
                    13156 Berlin
                    <br />
                    Deutschland
                    <br />
                    +49 30 48 49 42 41
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    <strong>Haftungsausschluss</strong>
                  </div>
                </div>
              </div>
            </div>
            <div>
              Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
              Zuverlässigkeit und Vollständigkeit der Informationen.
            </div>
            <div>
              Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff
              oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung
              oder durch technische Störungen entstanden sind, werden ausgeschlossen.
            </div>
            <div>
              Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
              gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
              Veröffentlichung zeitweise oder endgültig einzustellen.
            </div>
            <p>&nbsp;</p>
            <div>
              <strong>Haftung für Links</strong>
            </div>
            <div>
              Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird
              jegliche Verantwortung für solche Webseiten abgelehnt. Zugriff und Nutzung solcher Webseiten erfolgen auf
              eigene Gefahr des Nutzers oder der Nutzerin.
            </div>
            <p>&nbsp;</p>
            <div>
              <strong>Urheberrechte</strong>
            </div>
            <div>
              Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website
              gehören ausschliesslich&nbsp;TraceSpace – Scheffler &amp; Gessinger GbR oder den speziell genannten
              Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der
              Urheberrechtsträger im Voraus einzuholen.
            </div>
            <p>&nbsp;</p>
            <div>
              <strong>Datenschutz</strong>
            </div>
            <div>
              Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen
              des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
              Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten
              werden streng vertraulich behandelt und weder an Dritte verkauft noch weitergegeben.
            </div>
            <div>
              In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich
              vor fremden Zugriffen, Verlusten, Missbrauch oder Fälschung zu schützen.
            </div>
            <div>
              Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum,
              Uhrzeit, Browser-Anfrage und allgemein übertragene Informationen zum Betriebssystem resp. Browser. Diese
              Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, sodass Trends erkennbar sind,
              anhand derer wir unsere Angebote entsprechend verbessern können.
            </div>
            <p>&nbsp;</p>
            <div>
              <strong>Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like-Button)</strong>
            </div>
            <div>
              Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601 South California Avenue, Palo Alto,
              CA 94304, USA integriert. Die Facebook-Plugins erkennt man am Facebook-Logo oder «Like-Button» («Gefällt
              mir») auf unserer Seite. Eine Übersicht über die Facebook-Plugins findet sich hier:
              <a
                href="http://developers.facebook.com/docs/plugins/"
                rel="noreferrer"
                target="_blank"
                data-saferedirecturl="https://www.google.com/url?q=http://developers.facebook.com/docs/plugins/&amp;source=gmail&amp;ust=1652786415872000&amp;usg=AOvVaw3VXmD_GAqNC_cBSfE-V4Pi"
              >
                http://developers.
                <wbr />
                facebook.com/docs/plugins/
              </a>
              .
            </div>
            <div>
              Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und
              dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
              unsere Seite besucht haben. Wenn Sie den Facebook-«Like-Button» anklicken, während Sie in Ihrem
              Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil
              verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
              hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
              Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
              facebook unter
              <a
                href="https://www.facebook.com/about/privacy/"
                rel="noreferrer"
                target="_blank"
                data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&amp;source=gmail&amp;ust=1652786415872000&amp;usg=AOvVaw1bpZWGbVZcIWEcGkqM1IyJ"
              >
                https://www.facebook.com/
                <wbr />
                about/privacy/
              </a>
              .
            </div>
            <div>
              Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann,
              loggen Sie sich aus Ihrem Facebook-Benutzerkonto aus.
            </div>
            <p>&nbsp;</p>
            <div>
              <strong>Datenschutzerklärung für die Nutzung von Google Analytics</strong>
            </div>
            <div>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. («Google»). Google
              Analytics verwendet sog. «Cookies», Textdateien, die auf Ihrem Computer gespeichert werden und die eine
              Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über
              Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und
              dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse
              von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
              Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
            </div>
            <div>
              Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
              gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
              über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
              Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
              Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            </div>
            <div>
              Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
              Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich
              mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und
              zu dem zuvor benannten Zweck einverstanden.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Impressum;
