// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBg-ID7o4sonukSiyzjH7uLKI9hOBGDd9M",
  authDomain: "quicr-eu.firebaseapp.com",
  projectId: "quicr-eu",
  storageBucket: "quicr-eu.appspot.com",
  messagingSenderId: "1045526443553",
  appId: "1:1045526443553:web:0467ca6c130f8a7bd45560",
  measurementId: "G-0487V3WY65",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
