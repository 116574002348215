import React from "react";
import "./Pricing.css";
import { Link } from "react-router-dom";
import { Button } from "./Button";

function Pricing() {
  return (
    <div className="pricing__section">
      <div className="pricing__wrapper">
        <h1 className="pricing__heading">Unser Angebot</h1>
        <div className="pricing__container">
          <div className="pricing__container-card">
            <div className="pricing__container-cardInfo">
              <div className="icon">
                <img height="60px" src={"images/logo_main_light.png"} alt="logo" />
              </div>
              <h3>
                QUICR
                <br /> Starter Package
              </h3>
              <h4>6.700,00 €</h4>
              <ul className="pricing__container-features">
                <li>5 individuelle QR Codes</li>
                <li>2 Jahre Service inklusive*</li>
              </ul>
              <Link to="/contact?p=starter">
                <Button buttonStyle="btn--primary" buttonColor="primary" buttonSize="btn--large">
                  Kontaktiere uns
                </Button>
              </Link>
            </div>
          </div>
          <div className="pricing__container-card">
            <div className="pricing__container-cardInfo">
              <div className="icon">
                <img height="60px" src={"images/logo_main_light.png"} alt="logo" />
              </div>
              <h3>
                QUICR
                <br /> Advanced Package
              </h3>
              <h4>9.600,00 €</h4>
              <ul className="pricing__container-features">
                <li> 10 individuelle QR Codes</li>
                <li>2 Jahre Service inklusive*</li>
              </ul>
              <Link to="/contact?p=advanced">
                <Button buttonStyle="btn--primary" buttonColor="primary" buttonSize="btn--large">
                  Kontaktiere uns
                </Button>
              </Link>
            </div>
          </div>
          <div className="pricing__container-card">
            <div className="pricing__container-cardInfo">
              <div className="icon">
                <img height="60px" src={"images/logo_main_light.png"} alt="logo" />
              </div>
              <h3>QUICR Individual</h3>
              <h4>
                <br />
              </h4>
              <ul className="pricing__container-features">
                <li>
                  <br />
                </li>
                <li>
                  <br />
                </li>
                <li>
                  <br />
                </li>
              </ul>
              <Link to="/contact?p=individual">
                <Button buttonStyle="btn--primary" buttonColor="primary" buttonSize="btn--large">
                  Kontaktiere uns
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <p className="pricing__footnote">* Danach: 820,00€ pro Jahr</p>
        <p className="pricing__footnote" style={{ fontSize: "14px", marginTop: "15px" }}>
          Preise inklusive gesetzlich gültiger Mehrwertsteuer
        </p>
      </div>
    </div>
  );
}

export default Pricing;
