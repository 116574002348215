import React from "react";

export const errorStatus = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
};

const statusMessage = {
  401: "Unauthorized",
  403: "Forbidden",
  404: "Diese Seite konnte nicht gefunden werden.",
};

const ErrorPage = (props) => {
  return (
    <div style={{ padding: "15vh 0 15vh 0", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
      <h1 style={{ fontWeight: "bold", fontSize: "min(10vmin, 100px)", color: "var(--primaryContrastColor)" }}>
        {props.status}
      </h1>
      <h2 style={{ color: "var(--primaryContrastColor)" }}>{statusMessage[props.status]}</h2>
    </div>
  );
};

export default ErrorPage;
